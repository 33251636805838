import ServicesAPI from "@/services/api-services";
import RightModal from "@/views/RightModal";

export default {
  data() {
    return {
      isMounted: false,
      variable: '{{variable}}',
      type_trigger: [{
        type: 'notify',
        name: 'Notificaciones'
      }, {
        type: 'validate_exists',
        name: 'Validar existencia'
      }],
      type_execute: [{
        type: 'before',
        name: 'Antes de'
      }, {
        type: 'after',
        name: 'Después de'
      }],
      trigger: {
        trigger: this.uniqid(3),
        function: '',
        type_exec: '',
        type_trig: '',
        name: '',
        description: '',
        query: '',
        query_exists: false,
        parts: {}
      },
      currentFunction: {},
      triggers: [],
      functions: [],
      params: [],
      dataRightModal: {},
      RightModal: false
    }
  },
  methods: {
    setFunction() {
      this.currentFunction = this.functions.filter(fil => fil.function == this.trigger.function)[0];
    },
    setTypeTrigger() {

      if (typeof (this.trigger.parts) == 'undefined') return false;
      if (Object.keys(this.trigger.parts).length > 0) return false;

      switch (this.trigger.type_trig) {
        case 'notify':
          this.trigger.parts = {
            channels: [{
              type: 'notify',
              name: 'Notificación Web',
              value: false,
              query: `{"user": "VALOR_USUARIO", "message": "TEXTO_MENSAJE"}`
            }, {
              type: 'email',
              name: 'Correo electrónico',
              value: false,
              query: `{"templateID": "ID_PLANTILLA", "fromEmail": "EMAIL_REMITENTE", "fromName": "NOMBRE_REMITENTE", "TO": "[{"Name": "NOMBRE_DESTINO", "Email": "EMAIL_DESTINO"}]", "BCC": "[]", "subject": "SUBJECT",  "vars": "{"VAR1": "VALUE_VAR1", "VAR2": "VALUE_VAR2","VAR3": "VALUE_VAR3","VAR4": "VALUE_VAR4"}"}`
            }, {
              type: 'app',
              name: 'Push en APP',
              value: false,
              query: `{"user": "USUARIO", "title": "TITULO", "body": "MENSAJE", "data": "{}"}`
            }]
          };
          break;
      }
    },
    getMethods() {

      this.methods = [];

      ServicesAPI.methods.apiGET({
        url: 'api/method/get',
        params: {
          interface: this.$route.params.id_interface
        }
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        this.methods = res.data;
        this.getFunctions();
      });
    },
    void() {
      return false;
    },
    getFunctions() {

      let methods = [];

      for (const met of this.methods) {
        methods.push(met.method);
      }

      ServicesAPI.methods.apiGET({
        url: 'api/function/get',
        params: {}
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        let functions = [];

        for (const fun of res.data) {
          if (methods.indexOf(fun.method) >= 0) {
            functions.push(fun);
          }
        }

        functions = functions.sort(function (a, b) {
          if (a.method == b.method) {
            return 1;
          }
          if (a.method != b.method) {
            return -1;
          }
          return 0;
        });

        this.functions = functions;
      });
    },
    getTypeMethod(id) {
      return this.methods.filter(fil => fil.method == id)[0];
    },
    registerTrigger() {

      ServicesAPI.methods.apiPOST({
        url: 'api/trigger/register',
        params: this.trigger
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        this.trigger = {
          trigger: this.uniqid(3),
          function: '',
          type_exec: '',
          type_trig: '',
          name: '',
          description: '',
          query: '',
          query_exists: false,
          parts: {}
        }
        this.currentFunction = {};
      });
    },
    setTrigger(par, key) {
      this.trigger = this.triggers[key];
      this.RightModal = false;
    },
    getTriggers($ev) {
      $ev.preventDefault();

      ServicesAPI.methods.apiGET({
        url: 'api/trigger/get',
        params: this.trigger
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        const triggers = [];

        for (const tri of res.data) {
          triggers.push({
            name: tri.name,
            table: ''
          });
        }
        this.triggers = res.data;

        this.dataRightModal = {
          name: 'Disparadores registrados',
          id: 'editTrigger',
          data: {},
          fields: [
            {
              text: 'Elija un item de la lista para editar',
              name: 'relation_type',
              type: 'table_list',
              required: true,
              options: triggers,
              value: '',
              click: this.setTrigger
            }
          ],
          txtSendBtn: ''
        }

        this.RightModal = true;
      });
    },
    onEmit() {

      switch (this.dataRightModal.id) {
        case 'editTrigger':
          console.log('edit');
          break;
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.isMounted = true;
    this.getMethods();
  },
  components: {
    RightModal
  }
}
