<template>
  <vs-tabs position="left">
    <vs-tab label="Métodos" icon-pack="feather" icon="icon-box" to="/dashboard">
      <methods></methods>
    </vs-tab>
    <vs-tab label="Funciones" icon-pack="feather" icon="icon-code">
      <functions></functions>
    </vs-tab>
    <vs-tab label="Disparadores" icon-pack="feather" icon="icon-git-branch">
      <triggers></triggers>
    </vs-tab>
  </vs-tabs>
</template>

<script>

import RightModal from "./RightModal.vue";
import Functions from "./Interface/Function.vue";
import Methods from "./Interface/Method.vue";
import Triggers from "./Interface/Trigger.vue";

export default {
  data() {
    return {
      isMounted: false,
      states: [],
      RightModal: false,
      dataRightModal: {
        name: 'Nuevo estado',
        id: 'newState',
        data: {},
        fields: [
          {text: 'Nombre', name: 'nombre', type: 'text', required: true, value: ''},
          {
            text: 'Estado', name: 'activo', type: 'select', required: true, options: [
              {value: 0, text: 'Inactivo'},
              {value: 1, text: 'Activo'}
            ],
            value: ''
          },
          {text: 'Descripción', name: 'descripcion', type: 'textbox', required: true, value: ''},
        ],
        txtSendBtn: 'Enviar'
      }
    }
  },
  methods: {},
  mounted() {
    this.isMounted = true;
  },
  components: {
    RightModal,
    Functions,
    Methods,
    Triggers
  }
}
</script>

<style lang="scss">
.card {
  box-shadow: 1px 1px #a3a3a350 !important;

  p {
    font-size: 14px;
  }

  div {
    margin-bottom: 15px;
  }
}
</style>
