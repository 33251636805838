<template>
  <div class="vx-row">
    <div class="vx-col w-full sm:w-4/12 mb-base">
      <vs-card class="card">
        <form @submit.prevent="registerMethod">
          <div class="mt-2 flex items-center justify-between px-6">
            <h4>Nuevo</h4>
          </div>
          <vs-divider class="mb-0"></vs-divider>

          <div class="p-2">
            <vs-input class="mt-8 w-full"
                      icon-pack="feather"
                      icon="icon-edit"
                      type="text"
                      label-placeholder="Nombre"
                      v-model="name"
                      required/>

            <div>
              <label style="font-size: 12px">Tipos</label>
              <vs-checkbox class="mt-2" :color="type.color" v-model="type.value" :name="type.name" :key="type.type"
                           v-for="type in types">{{type.name}}
              </vs-checkbox>
            </div>

            <vs-input class="mt-8 w-full"
                      icon-pack="feather"
                      icon="icon-link"
                      type="text"
                      label-placeholder="Url"
                      v-model="url"
                      required/>
          </div>

          <div class="flex flex-wrap items-center justify-center p-0" slot="footer">
            <vs-button :disabled="name != '' && url != '' ? false : true">Registrar</vs-button>
          </div>
        </form>
      </vs-card>
    </div>
    <div class="vx-col w-full sm:w-8/12 mb-base">
      <vs-card class="card">
        <p>
          Listado de métodos métodos, segmentados por tipo. Estos métodos se pódrán utilizar por las funciones para
          agrupar lod datos de respuesta.
        </p>
      </vs-card>
    </div>
  </div>
</template>

<script>

  import ServicesAPI from "@/services/api-services";
  import RightModal from "../RightModal.vue";

  export default {
    data() {
      return {
        isMounted: false,
        RightModal: false,
        types: [{
          type: 'get',
          color: 'primary',
          name: 'GET',
          value: true
        }, {
          type: 'post',
          color: 'success',
          name: 'POST',
          value: true
        }, {
          type: 'put',
          color: 'warning',
          name: 'POST',
          value: true
        }, {
          type: 'delete',
          color: 'danger',
          name: 'DELETE',
          value: true
        }],
        interface: '',
        name: '',
        url: '/',
      }
    },
    methods: {
      registerMethod() {

        let methods = [];

        for (let type of this.types) {

          if (type.value)
            methods.push({
              method: this.uniqid(3),
              type: type.type,
              interface: this.$route.params.id_interface,
              name: this.name,
              url: this.url
            });
        }

        ServicesAPI.methods.apiPOST({
          url: 'api/method/register',
          params: methods
        }, res => {

          if (res.code != 200)
            this.$vs.notify({
              title: res.code,
              text: res.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });

          this.name = '';
          this.url = '/';
        });
      }
    },
    mounted() {
      this.isMounted = true;
    },
    components: {
      RightModal
    }
  }
</script>


