<template>
  <div class="vx-row">
    <right-modal v-on:eventsend="onEmit" :dataModal="dataRightModal" :isSidebarActive="RightModal"
                 @closeSidebar="RightModal = false"/>
    <div class="vx-col w-full sm:w-4/12 mb-base">
      <vs-card class="card">
        <form @submit.prevent="registerFunction">
          <div class="mt-2 flex items-center justify-between px-6">
            <h4>{{ current_id != '' ? name : 'Nueva' }}</h4>
          </div>
          <vs-divider class="mb-0"></vs-divider>

          <div class="p-2">
            <vs-select label="Método" class="mt-2 w-full" v-model="method" required>
              <vs-select-item :value="met.method" :text="met.type.toUpperCase() + met.url"
                              v-for="met in methods" :key="met.method"/>
            </vs-select>

            <vs-input class="mt-8 w-full"
                      icon-pack="feather"
                      icon="icon-edit"
                      type="text"
                      label-placeholder="Nombre"
                      v-model="name"
                      required/>

            <vs-input class="mt-8 w-full"
                      icon-pack="feather"
                      icon="icon-edit"
                      type="text"
                      label-placeholder="ID"
                      v-model="id"
                      required/>

            <vs-textarea label="Descripción" v-model="description" class="mt-5 w-full" rows="3"
                         name="description"/>

          </div>

          <div class="flex flex-wrap float-center justify-center p-0" slot="footer">
            <vs-button :disabled="dataTables.length == 0 ? true : false">Registrar</vs-button>
          </div>
        </form>
      </vs-card>
    </div>
    <div class="vx-col w-full sm:w-8/12 mb-base">
      <vs-card class="card">
        <p>
          Confección de los diferentes parámetros de la función, en esta sección deberá elegir las tablas para formar
          el cuerpo de respuesta
          <vs-button radius class="float-right" color="primary" type="border" icon-pack="feather"
                     icon="icon-plus" @click="modalTable()"></vs-button>
          <br><br>
        </p>
      </vs-card>
      <draggable class="mb-5" v-model="dataTables" group="people" @start="drag=true" @end="drag=false">
        <vs-list-item v-for="(table, key) in dataTables" :key="table.id"
                      :title="table.name + ' - ' + table.table"
                      :subtitle="`Campos: ${table.fields.length}, Parámetros: ${table.params.length}, Hijos: ${table.childs.length}`">
          <vs-button radius class="float-right" color="primary" type="border" icon-pack="feather"
                     icon="icon-edit" @click="preparEdit(key)"></vs-button>
          <vs-button radius class="float-right" color="primary" type="border"
                     icon-pack="feather"
                     icon="icon-git-merge" @click="prepareChild(table.table, key)"
                     style="margin-right: 10px !important;"></vs-button>
          <vs-button radius class="float-right" color="danger" type="border" icon-pack="feather"
                     icon="icon-trash" @click="remove(key)"
                     style="margin-right: 10px !important;"></vs-button>
          <vs-button radius class="float-right" color="primary" type="border"
                     v-if="table.childs.length > 0"
                     icon-pack="feather"
                     icon="icon-list" @click="showChilds(key)"
                     style="margin-right: 10px !important;"></vs-button>
        </vs-list-item>
      </draggable>
      <vs-card class="card">
        <p>
          A continuación se listan los parámetros que será necesario enviar al momento de utilizar esta función.
        </p><br>
        <div class="mb-10" v-if="params.length > 0">
          <vs-chip v-for="param in params" :key="param">{{ param }}</vs-chip>
        </div>
      </vs-card>

      <vs-card class="card">
        <vs-table
          :sst="true"
          v-model="selectedFunction"
          pagination
          max-items="3"
          search
          :data="functions">

          <template slot="header">
            <h3>Funciones registradas</h3>
          </template>

          <template slot="thead">
            <vs-th sort-key="method">Método</vs-th>
            <vs-th sort-key="username">URL</vs-th>
            <vs-th sort-key="website">Nombre</vs-th>
            <vs-th sort-key="id">ID</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ getTypeMethod(data[indextr].method).type.toUpperCase() }}
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{ getTypeMethod(data[indextr].method).url }}
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </div>
  </div>
</template>

<script>

import ServicesAPI from "@/services/api-services";
import RightModal from "../RightModal.vue";
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      isMounted: false,
      current_id: '',
      methods: [],
      tables: [],
      fields: [],
      name: '',
      id: '',
      method: '',
      description: '',
      dataTables: [],
      params: [],
      RightModal: false,
      dataRightModal: {},
      functions: [],
      selectedFunction: ''
    }
  },
  methods: {
    remove(id) {

      if (confirm("Está seguro que desea eliminar la clave")) {
        this.dataTables.splice(id, 1);
      }
    },
    preparEdit(id) {

      const dataTables = [], selected = this.selectedFunction.sql[id];

      for (const tab of this.tables) {
        dataTables.push({
          value: tab,
          text: tab
        });
      }

      this.dataRightModal = {
        name: 'Agregar tabla',
        id: 'editTable',
        data: {
          key: id
        },
        fields: [
          {text: 'Nombre', name: 'name', type: 'text', required: true, value: selected.name},
          {
            text: 'Elija la tabla', name: 'table', type: 'select', required: true, options: dataTables,
            value: selected.table,
            change: this.getFields
          },
          {text: 'Campos', name: 'fields', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Parámetros', name: 'params', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Ordenamiento (asc)', name: 'params', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Búsqueda', name: 'search', type: 'checkboxes', required: false, value: '', options: []}
        ],
        txtSendBtn: 'Asignar'
      }

      this.getFields(selected.table);

      this.RightModal = true;
    },
    modalTable() {

      const dataTables = [];

      for (const tab of this.tables) {
        dataTables.push({
          value: tab,
          text: tab
        });
      }

      this.dataRightModal = {
        name: 'Agregar tabla',
        id: 'newTable',
        data: {},
        fields: [
          {text: 'Nombre', name: 'name', type: 'text', required: true, value: ''},
          {
            text: 'Elija la tabla', name: 'table', type: 'select', required: true, options: dataTables,
            value: '',
            change: this.getFields
          },
          {text: 'Campos', name: 'fields', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Parámetros', name: 'params', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Ordenamiento (asc)', name: 'params', type: 'checkboxes', required: false, value: '', options: []},
          {text: 'Búsqueda', name: 'search', type: 'checkboxes', required: false, value: '', options: []}
        ],
        txtSendBtn: 'Asignar'
      }

      this.RightModal = true;
    },
    getMethods() {

      this.methods = [];

      ServicesAPI.methods.apiGET({
        url: 'api/method/get',
        params: {
          interface: this.$route.params.id_interface
        }
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        this.methods = res.data;
        this.getFunctions();
      });
    },
    getTables() {
      ServicesAPI.methods.apiGET({
        url: 'api/table/get',
        params: {}
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        this.tables = res.data.tables;
      });
    },
    void() {
      return false;
    },
    getFields(table) {

      if (table == '')
        return false;

      ServicesAPI.methods.apiGET({
        url: 'api/table/fields',
        params: {
          table: table
        }
      }, res => {

        let dataFields = [], dataParams = [], dataOrdering = [], dataSearch = [];

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        for (const fie of res.data.fields) {
          dataFields.push({
            field: fie.Field,
            value: false
          });
          dataParams.push({
            field: fie.Field,
            value: false
          });
          dataOrdering.push({
            field: fie.Field,
            value: false
          });
          dataSearch.push({
            field: fie.Field,
            value: false
          });
        }

        if (this.dataRightModal.fields[2].type == "checkboxes")
          this.dataRightModal.fields[2].options = dataFields;

        if (this.dataRightModal.fields[3].type == "checkboxes")
          this.dataRightModal.fields[3].options = dataParams;

        if (this.dataRightModal.fields[4].type == "checkboxes")
          this.dataRightModal.fields[4].options = dataOrdering;

        if (this.dataRightModal.fields[5].type == "checkboxes")
          this.dataRightModal.fields[5].options = dataSearch;

        if (typeof (this.dataTables[this.dataRightModal.data.key]) != "undefined") {

          let selected = this.dataTables[this.dataRightModal.data.key];

          for (const fie of selected.fields) {
            let field = dataFields.filter(fil => fil.field == fie)[0];
            field.value = true;
          }

          for (const fie of selected.params) {
            let param = dataParams.filter(fil => fil.field == fie)[0];
            param.value = true;
          }

          for (const fie of selected.order) {
            let orde = dataOrdering.filter(fil => fil.field == fie)[0];
            orde.value = true;
          }

          for (const fie of selected.search) {
            let sear = dataSearch.filter(fil => fil.field == fie)[0];
            sear.value = true;
          }
        }
      });
    },
    getFieldsChild(table, child = false) {

      if (table == '')
        return false;

      ServicesAPI.methods.apiGET({
        url: 'api/table/fields',
        params: {
          table: table
        }
      }, res => {

        let dataFields = [], dataParams = [];

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        for (const fie of res.data.fields) {
          dataFields.push({
            field: fie.Field,
            value: child ? child.fields.indexOf(fie.Field) >= 0 ? true : false : false
          });
          dataParams.push(fie.Field);
        }

        if (this.dataRightModal.fields[3].type == "checkboxes")
          this.dataRightModal.fields[3].options = dataFields;

        this.dataRightModal.fields[4].compare[0].destination.options = dataParams;
      });
    },
    getFunctions() {

      let methods = [];

      for (const met of this.methods) {
        methods.push(met.method);
      }

      ServicesAPI.methods.apiGET({
        url: 'api/function/get',
        params: {}
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        let functions = [];

        for (const fun of res.data) {
          if (methods.indexOf(fun.method) >= 0) {
            functions.push(fun);
          }
        }

        this.functions = functions;
      });
    },
    getTypeMethod(id) {
      return this.methods.filter(fil => fil.method == id)[0];
    },
    registerFunction() {

      ServicesAPI.methods.apiPOST({
        url: 'api/function/register',
        params: {
          function: this.current_id != '' ? this.current_id : this.uniqid(3),
          id: this.id,
          method: this.method,
          name: this.name,
          params: this.params,
          description: this.description,
          sql: this.dataTables
        }
      }, res => {

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        this.name = '';
        this.method = '';
        this.description = '';
        this.dataTables = [];
        this.params = [];
        this.current_id = '';

        this.getFunctions();
      });
    },
    showChilds(key) {

      this.dataRightModal = {
        name: 'Lita de hijos',
        id: 'getChild',
        data: {
          parent: key
        },
        fields: [
          {
            text: 'Elija un item de la lista para editar',
            name: 'relation_type',
            type: 'table_list',
            required: true,
            options: this.dataTables[key].childs,
            value: '',
            click: this.editChild
          }
        ],
        txtSendBtn: ''
      }

      this.RightModal = true;
    },
    editChild(parent, key) {

      let dataTable = this.dataTables[parent].childs[key];
      dataTable.key = key;
      this.prepareChild(this.dataTables[parent].table, parent, dataTable);
    },
    prepareChild(table, key, child = false) {

      if (table == '')
        return false;

      ServicesAPI.methods.apiGET({
        url: 'api/table/fields',
        params: {
          table: table
        }
      }, res => {

        let dataOrigin = [], dataDestination = [];

        if (res.code != 200)
          this.$vs.notify({
            title: res.code,
            text: res.message,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          });

        for (const fie of res.data.fields) {
          dataOrigin.push(fie.Field);
        }

        const dataTables = [];
        let dataCompare = [];

        for (const tab of this.tables) {
          dataTables.push({
            value: tab,
            text: tab
          });
        }

        if (child) {

          for (const fie of child.params) {
            dataCompare.push({
              origin: {
                value: fie.origin,
                options: dataOrigin
              },
              destination: {
                value: fie.destination,
                options: []
              }
            })
          }
        } else {
          dataCompare = [{
            origin: {
              value: '',
              options: dataOrigin
            },
            destination: {
              value: '',
              options: []
            }
          }]
        }

        this.dataRightModal = {
          name: 'Agregar relacion',
          id: child ? 'editChild' : 'newChild',
          data: {
            parent: key,
            child: child.key
          },
          fields: [{
            text: 'Elija el tipo de relación', name: 'relation_type', type: 'select', required: true, options: [{
              value: 'NONE',
              text: 'Ninguna'
            }, {
              value: 'ASSOCIATED',
              text: 'Asociativa'
            }, {
              value: 'POSASSOCIATED',
              text: 'Posible asociación'
            }, {
              value: 'CHILDREN',
              text: 'Detallada (Hijos)'
            }, {
              value: 'EXISTS',
              text: 'Existencia'
            }, {
              value: 'OREXISTS',
              text: 'Posible existencia'
            }],
            value: child.relation_type,
            change: this.void
          },
            {text: 'Nombre', name: 'name', type: 'text', required: true, value: child.name},
            {
              text: 'Elija la tabla', name: 'table', type: 'select', required: true, options: dataTables,
              value: child.table,
              change: this.getFieldsChild
            },
            {
              text: 'Campos en la tabla',
              name: 'fields',
              type: 'checkboxes',
              required: false,
              value: '',
              options: []
            },
            {
              text: 'Nombre',
              name: 'name',
              type: 'table',
              required: false,
              value: '',
              options: ['Campo relacion', 'Campo padre'],
              compare: dataCompare
            },
          ],
          txtSendBtn: 'Asociar'
        }

        if (child) {
          this.getFieldsChild(child.table, child);
        }

        this.RightModal = true;
      });
    },
    getParams() {

      this.params = [];

      for (const par of this.dataTables) {

        for (const fie of par.params) {

          if (this.params.indexOf(fie) < 0)
            this.params.push(fie);
        }

        for (const ch of par.childs) {

          if (ch.relation_type == 'EXISTS' || ch.relation_type == 'OREXISTS') {

            for (const fi of ch.fields) {
              if (this.params.indexOf(fi) < 0)
                this.params.push(fi);
            }
          }
        }
      }
    },
    onEmit() {

      switch (this.dataRightModal.id) {
        case 'newTable':

          var fields = [], params = [], order = [], search = [];

          for (const fie of this.dataRightModal.fields[2].options) {
            if (fie.value)
              fields.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[3].options) {
            if (fie.value)
              params.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[4].options) {
            if (fie.value)
              order.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[5].options) {
            if (fie.value)
              search.push(fie.field);
          }

          this.dataTables.push({
            table: this.dataRightModal.fields[1].value,
            name: (this.dataRightModal.fields[0].value).toLowerCase(),
            fields: fields,
            params: params,
            order: order,
            search: search,
            childs: []
          });
          this.getParams();

          this.RightModal = false;
          break;
        case 'editTable':

          var fields = [], params = [], order = [], search = [];

          for (const fie of this.dataRightModal.fields[2].options) {
            if (fie.value)
              fields.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[3].options) {
            if (fie.value)
              params.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[4].options) {
            if (fie.value)
              order.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[5].options) {
            if (fie.value)
              search.push(fie.field);
          }

          this.dataTables[this.dataRightModal.data.key] = {
            table: this.dataRightModal.fields[1].value,
            name: (this.dataRightModal.fields[0].value).toLowerCase(),
            fields: fields,
            params: params,
            order: order,
            search: search,
            childs: this.dataTables[this.dataRightModal.data.key].childs
          };
          this.getParams();

          this.RightModal = false;
          break;
        case 'newChild':

          var fields = [], params = [];

          for (const fie of this.dataRightModal.fields[3].options) {
            if (fie.value)
              fields.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[4].compare) {
            params.push({
              origin: fie.origin.value,
              destination: fie.destination.value
            });
          }

          this.dataTables[this.dataRightModal.data.parent].childs.push({
            table: this.dataRightModal.fields[2].value,
            relation_type: this.dataRightModal.fields[0].value,
            name: (this.dataRightModal.fields[1].value).toLowerCase(),
            fields: fields,
            params: params
          });
          this.getParams();

          this.RightModal = false;
          break;
        case 'editChild':

          var fields = [], params = [];

          for (const fie of this.dataRightModal.fields[3].options) {
            if (fie.value)
              fields.push(fie.field);
          }

          for (const fie of this.dataRightModal.fields[4].compare) {
            params.push({
              origin: fie.origin.value,
              destination: fie.destination.value
            });
          }

          this.dataTables[this.dataRightModal.data.parent].childs[this.dataRightModal.data.child] = {
            table: this.dataRightModal.fields[2].value,
            relation_type: this.dataRightModal.fields[0].value,
            name: (this.dataRightModal.fields[1].value).toLowerCase(),
            fields: fields,
            params: params
          };
          this.getParams();

          this.RightModal = false;
          break;
      }
    },
  },
  watch: {
    selectedFunction(val, oldVal) {

      this.id = val.id;
      this.method = val.method;
      this.name = val.name;
      this.params = val.params;
      this.dataTables = val.sql;
      this.description = val.description;
      this.current_id = val.function;
    }
  },
  mounted() {
    this.isMounted = true;
    this.getTables();
    this.getMethods();
  },
  components: {
    RightModal,
    draggable
  }
}
</script>
